import React, { useEffect, useState } from "react";
import "./AnimationSlider.css";
import { Link } from "react-router-dom";

const AnimationSlider = () => {
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    const nextButton = document.getElementById("next");
    const prevButton = document.getElementById("prev");
    const carousel = document.querySelector(".carousel");
    const listHTML = document.querySelector(".carousel .list");
    const seeMoreButtons = document.querySelectorAll(".seeMore");
    const backButton = document.getElementById("back");
    let unAcceppClick;

    const showSlider = (type) => {
      nextButton.style.pointerEvents = "none";
      prevButton.style.pointerEvents = "none";

      carousel.classList.remove("next", "prev");
      let items = document.querySelectorAll(".carousel .list .item");
      if (type === "next") {
        listHTML.appendChild(items[0]);
        carousel.classList.add("next");
      } else {
        listHTML.prepend(items[items.length - 1]);
        carousel.classList.add("prev");
      }
      clearTimeout(unAcceppClick);
      unAcceppClick = setTimeout(() => {
        nextButton.style.pointerEvents = "auto";
        prevButton.style.pointerEvents = "auto";
      }, 2000);
    };

    nextButton.onclick = () => {
      showSlider("next");
    };

    prevButton.onclick = () => {
      showSlider("prev");
    };

    seeMoreButtons.forEach((button) => {
      button.onclick = () => {
        carousel.classList.remove("next", "prev");
        carousel.classList.add("showDetail");
      };
    });

    backButton.onclick = () => {
      carousel.classList.remove("showDetail");
    };

    return () => {
      if (nextButton) nextButton.onclick = null;
      if (prevButton) prevButton.onclick = null;
      seeMoreButtons.forEach((button) => {
        button.onclick = null;
      });
      if (backButton) backButton.onclick = null;
    };
  }, []);

  return (
    <div className="carousel">
      <div className="list">
        <div className="item">
          <Link to="/product/66b4ebde24a0df457eefccde">
            <img src="anime/GOKU_OVERSIZED_SKY_BLUE_LIMITED.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">DRAGON BALL Z</div>
            <div className="des">
              "Channel your Saiyan spirit with our exclusive Dragon Ball Z anime
              T-shirt! Featuring iconic characters and electrifying designs,
              this shirt is a must-have for any true DBZ fan. Made from premium,
              comfortable fabric."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">DRAGON BALL Z</div>
            <div className="des">
              Dragon Ball Z t-shirts are a must-have for any anime enthusiast.
              Featuring iconic characters like Goku, Vegeta, and Gohan, these
              shirts bring the action-packed world of Dragon Ball Z to life.
              Made from comfortable, high-quality materials, they are perfect
              for casual wear or conventions. With vibrant designs and bold
              colors, these t-shirts let fans proudly display their love for the
              series.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/675867be4cec212f5e7b65cf">
            <img src="anime/WOLVERINE.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">MARVEL</div>
            <div className="topic">WOLVERINE</div>
            <div className="des">
            A Wolverine t-shirt features bold designs showcasing the iconic Marvel character, combining superhero style with comfort for fans.
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">WOLVERINE</div>
            <div className="des">
            A Wolverine and Deadpool t-shirt blends their iconic styles, showcasing Marvel’s dynamic duo with action-packed, edgy, and bold designs.
           
            </div>
          </div>
        </div>

        



        <div className="item">
          <Link to="/product/67185f830177e69101d73a76">
            <img src="anime/BATMAN_BEN_AFFLECK.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">DC</div>
            <div className="topic">BATMAN</div>
            <div className="des">
            "Elevate Your Style with Our Unique Batman Anime T-Shirt: A Perfect Blend of Classic Heroism and Dynamic Art, Ideal for Fans Who Love to Stand Out and Embrace Their Inner Crusader!"
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">BATMAN</div>
            <div className="des">
            "Unleash your inner hero with this striking Batman anime T-shirt! Featuring a unique blend of classic Batman imagery and dynamic anime art, this shirt showcases Gotham’s dark knight in a bold, eye-catching style."
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/66b4fc7c0594f27b00f3f931">
            <img
              src="anime/SPIDERMAN_OVERSIZED_SKY_BLUE_LIMITED.png"
              alt="Airpod"
            />
          </Link>

          <div className="introduce">
            <div className="header-title">MOVIE</div>
            <div className="topic">SPIDER MAN</div>
            <div className="des">
              "Unleash your inner hero with our exclusive Spider-Man anime
              T-shirt! Featuring a dynamic design that captures the essence of
              your favorite web-slinger, this shirt is perfect for fans who want
              to swing into action with style."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">SPIDER MAN</div>
            <div className="des">
              Spider-Man t-shirts are a fantastic way to showcase your love for
              the iconic web-slinger. Featuring dynamic designs of Spider-Man in
              action, these shirts capture the essence of Marvel's beloved
              superhero. Made from soft, durable fabrics, they are ideal for
              everyday wear or special events. With a variety of styles and
              prints, from classic comic book art to modern movie renditions,
              there's a Spider-Man t-shirt for every fan.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/671c70730177e69101d74c7c">
            <img src="anime/AIZEN_WHITE.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">BLEACH</div>
            <div className="des">
            "Master of Deception, Aizen’s Ambition Unleashed. With power and elegance, he reigns over Soul Society. Trust no one; illusion is his art. Embrace the chaos, join the revolution!"
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">AIZEN</div>
            <div className="des">
            "Deception is an art, and Aizen is the artist. With every move, he redefines power and control. Join the revolution, challenge the norm, and discover what lies beneath the surface of Soul Society."
            </div>
          </div>
        </div>







        <div className="item">
          <Link to="/product/66afa888d525a49424eb2533">
            <img
              src="anime/DEMON_SLAYER_OVERSIZED_WHITE_LIMITED.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">DEMON SLAYER</div>
            <div className="des">
              "Step into the world of Demon Slayer with our exclusive anime
              T-shirt! Showcasing stunning artwork of your favorite characters
              like Tanjiro and Nezuko, this shirt is a must-have for any fan of
              the series. Crafted from high-quality, comfortable fabric."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">DEMON SLAYER</div>
            <div className="des">
              Demon Slayer t-shirts are perfect for fans of the popular anime
              series. Featuring stunning artwork of characters like Tanjiro,
              Nezuko, and Inosuke, these shirts capture the show's vibrant and
              intense aesthetic. Crafted from high-quality materials, they offer
              both comfort and durability for everyday wear. With designs
              ranging from minimalist to detailed scenes, there's a style to
              match every fan's taste.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/66afaa5ad525a49424eb284a">
            <img src="anime/KAISEN_OVERSIZED_WHITE_LIMITED.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">JUJUTSU KAISEN</div>
            <div className="des">
              "Immerse yourself in the supernatural battles of Jujutsu Kaisen
              with our exclusive anime T-shirt! Featuring striking designs of
              iconic characters like Yuji, Gojo, and Megumi, this shirt is a
              must-have for any fan of the series. Made from premium,
              comfortable fabric."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">JUJUTSU KAISEN</div>
            <div className="des">
              Jujutsu Kaisen t-shirts are a must-have for fans of the thrilling
              anime series. Showcasing characters like Yuji Itadori, Satoru
              Gojo, and Megumi Fushiguro, these shirts bring the intense and
              dark world of Jujutsu Kaisen to life. Made from soft, high-quality
              fabric, they ensure comfort while displaying your fandom. With
              bold and intricate designs, these t-shirts capture the essence of
              the series' action-packed and supernatural elements.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/66afaaadd525a49424eb28c1">
            <img
              src="anime/LEGENDARY_OVERSIZED_WHITE_LIMITED.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">NARUTO LEGENDARY SANINS</div>
            <div className="des">
              "Embrace the ninja way with our exclusive Naruto anime T-shirt!
              Featuring bold designs of legendary characters like Naruto,
              Sasuke, and Kakashi, this shirt is essential for any fan of the
              Hidden Leaf Village. Made from high-quality, comfortable fabric."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">NARUTO LEGENDARY SANINS</div>
            <div className="des">
              Naruto t-shirts featuring the Legendary Sannin are a tribute to
              some of the series' most iconic characters. Showcasing Jiraiya,
              Tsunade, and Orochimaru, these shirts highlight the powerful
              trio's unique abilities and dynamic personalities. Crafted from
              comfortable, high-quality materials, they are perfect for everyday
              wear or anime events.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/66b4fc3e0594f27b00f3f837">
            <img
              src="anime/SOLO_LEVELING_OVERSIZED_SKY_BLUE_LIMITED.png"
              alt="Airpod"
            />
          </Link>

          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">SOLO LEVELING</div>
            <div className="des">
              "Step into the world of Solo Leveling with our exclusive anime
              T-shirt! Featuring dynamic designs of Sung Jin-Woo and other key
              characters, this shirt is a must-have for any fan of the epic
              manhwa. Crafted from premium, comfortable fabric"
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">SOLO LEVELING</div>
            <div className="des">
              Solo Leveling t-shirts are a fantastic way for fans to show their
              love for the popular web novel and manhwa. Featuring striking
              designs of characters like Sung Jin-Woo, these shirts capture the
              intense and stylish essence of the series. Made from high-quality,
              comfortable materials.
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/66b4fb2a0594f27b00f3f27b ">
            <img src="anime/NARUTO_SKY_BLUE_LIMITED.png" alt="Airpod" />
          </Link>

          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">NARUTO</div>
            <div className="des">
              "Step into the world of ninjas, jutsus, and epic battles with our
              exclusive collection of Naruto anime T-shirts. Whether you're a
              loyal fan of Naruto Uzumaki, the determined Sasuke Uchiha, or the
              wise Kakashi Hatake, our shirts are designed to bring your
              favorite characters to life."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">NARUTO</div>
            <div className="des">
              Embrace the spirit of Konoha with our dynamic range of Naruto
              anime T-shirts. Perfect for every ninja in training, our shirts
              feature bold designs that capture the essence of the Naruto
              series. Whether you’re repping the Hidden Leaf Village or showing
              off your love for the Akatsuki.
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/66afa8e1d525a49424eb25bd">
            <img
              src="anime/FORBIDDEN_FOUR_OVERSIZED_WHITE_LIMITED.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">FORBIDDEN FOUR</div>
            <div className="des">
              "Dive into the dark and mysterious world of the "Forbidden Four"
              with our exclusive T-shirt. This design is perfect for fans who
              appreciate the edgier side of anime, featuring four legendary
              figures whose powers are both revered and feared."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">FORBIDDEN FOUR</div>
            <div className="des">
              By wearing the "Forbidden Four" T-shirt, you're not just
              showcasing your love for anime—you're aligning yourself with a
              select group of fans who appreciate the depth, power, and mystery
              of these iconic characters. Whether you're at a convention, out
              with friends, or just enjoying some downtime, this T-shirt is your
              badge of honor in the world of anime.
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/66afacd7d525a49424eb2c5f">
            <img
              src="anime/RED_HAIRED_SHANKS_OVERSIZED_WHITE_LIMITED.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">SHANKS</div>
            <div className="des">
              "This T-shirt features Shanks, the legendary pirate and captain of
              the Red-Haired Pirates, known for his calm demeanor, incredible
              strength, and unwavering sense of justice."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">SHANKS</div>
            <div className="des">
              Set sail on the Grand Line with our exclusive Shanks T-shirt, a
              tribute to one of the most iconic and revered characters in the
              world of anime. Whether you're a seasoned fan of the series or new
              to the adventures of the Straw Hat Pirates, this T-shirt is a
              must-have for any true fan.
            </div>
          </div>
        </div>
      </div>
      <div className="arrows">
        <button id="prev">
          {" "}
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <button id="next">
          {" "}
          <i class="fa-solid fa-chevron-right"></i>{" "}
        </button>
        <button
          id="back"
          className={showDetail ? "" : "block"}
          onClick={() => setShowDetail(false)}
        >
          See All &#8599;
        </button>
      </div>
    </div>
  );
};

export default AnimationSlider;
